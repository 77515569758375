import React from 'react'
import Link from 'gatsby-link'
import Layout from '../layouts/en'

const SecondPage = props => (
  <Layout location={props.location}>
    <div>
      <h1>Page 2</h1>
      <Link to="/">Go back to the homepage</Link>
    </div>
  </Layout>
)

export default SecondPage
